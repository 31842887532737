import _ElementUI2 from "element-ui/lib/theme-chalk/index.css";
import _ElementUI from "element-ui/lib";
import "C:\\workSpace\\study_test\\poetize-vue2\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "C:\\workSpace\\study_test\\poetize-vue2\\node_modules\\core-js\\modules\\es.promise.js";
import "C:\\workSpace\\study_test\\poetize-vue2\\node_modules\\core-js\\modules\\es.object.assign.js";
import "C:\\workSpace\\study_test\\poetize-vue2\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './utils/request';
import common from './utils/common';
import constant from './utils/constant';
import mavonEditor from 'mavon-editor'; //引入js

import './utils/live2d';
import './utils/title'; //引入css

import './assets/css/animation.css';
import './assets/css/index.css';
import './assets/css/tocbot.css';
import './assets/css/color.css';
import './assets/css/markdown-highlight.css';
import './assets/css/font-awesome.min.css';
import 'mavon-editor/dist/css/index.css';
import { vueBaberrage } from 'vue-baberrage';
Vue.use(_ElementUI);
Vue.use(vueBaberrage);
Vue.use(mavonEditor);
Vue.prototype.$http = http;
Vue.prototype.$common = common;
Vue.prototype.$constant = constant;
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');