import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import axios from "axios";
import constant from "./constant"; //处理url参数

import qs from "qs";
import store from "../store";
axios.defaults.baseURL = constant.baseURL; // 添加请求拦截器

axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
}); // 添加响应拦截器

axios.interceptors.response.use(function (response) {
  if (response.data !== null && response.data.hasOwnProperty("code") && response.data.code !== 200) {
    if (response.data.code === 300) {
      store.commit("loadCurrentUser", {});
      localStorage.removeItem("userToken");
      store.commit("loadCurrentAdmin", {});
      localStorage.removeItem("adminToken");
      window.location.href = constant.webURL + "/user";
    }

    return Promise.reject(new Error(response.data.message));
  } else {
    return response;
  }
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
}); // 当data为URLSearchParams对象时设置为application/x-www-form-urlencoded;charset=utf-8
// 当data为普通对象时，会被设置为application/json;charset=utf-8

export default {
  post: function post(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var isAdmin = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var json = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var config;

    if (isAdmin) {
      config = {
        headers: {
          "Authorization": localStorage.getItem("adminToken")
        }
      };
    } else {
      config = {
        headers: {
          "Authorization": localStorage.getItem("userToken")
        }
      };
    }

    return new Promise(function (resolve, reject) {
      axios.post(url, json ? params : qs.stringify(params), config).then(function (res) {
        resolve(res.data);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  get: function get(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var isAdmin = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var headers;

    if (isAdmin) {
      headers = {
        "Authorization": localStorage.getItem("adminToken")
      };
    } else {
      headers = {
        "Authorization": localStorage.getItem("userToken")
      };
    }

    return new Promise(function (resolve, reject) {
      axios.get(url, {
        params: params,
        headers: headers
      }).then(function (res) {
        resolve(res.data);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  upload: function upload(url, param) {
    var isAdmin = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var config;

    if (isAdmin) {
      config = {
        headers: {
          "Authorization": localStorage.getItem("adminToken"),
          "Content-Type": "multipart/form-data"
        },
        timeout: 60000
      };
    } else {
      config = {
        headers: {
          "Authorization": localStorage.getItem("userToken"),
          "Content-Type": "multipart/form-data"
        },
        timeout: 60000
      };
    }

    return new Promise(function (resolve, reject) {
      axios.post(url, param, config).then(function (res) {
        resolve(res.data);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  uploadQiniu: function uploadQiniu(url, param) {
    var config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 60000
    };
    return new Promise(function (resolve, reject) {
      axios.post(url, param, config).then(function (res) {
        resolve(res.data);
      }).catch(function (err) {
        reject(err);
      });
    });
  }
};